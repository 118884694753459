// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-courses-learn-web-development-html-and-css-js": () => import("./../../../src/pages/courses/learn-web-development-html-and-css.js" /* webpackChunkName: "component---src-pages-courses-learn-web-development-html-and-css-js" */),
  "component---src-pages-courses-learn-web-development-html-css-javascript-and-react-js": () => import("./../../../src/pages/courses/learn-web-development-html-css-javascript-and-react.js" /* webpackChunkName: "component---src-pages-courses-learn-web-development-html-css-javascript-and-react-js" */),
  "component---src-pages-courses-learn-web-development-javascript-and-react-js": () => import("./../../../src/pages/courses/learn-web-development-javascript-and-react.js" /* webpackChunkName: "component---src-pages-courses-learn-web-development-javascript-and-react-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

